import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
