import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';

import { showNotification } from 'Store/Notification/Notification.action';
import { NotificationType } from 'Store/Notification/Notification.type';
import { prepareQuery } from 'Util/Query';
import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import { executeGet } from 'Util/Request/Request';
import agtm from './../../gtm/tags';

export {
    mapDispatchToProps,
    mapStateToProps,
};

/** @namespace AlmusbahblendPwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    // TODO implement logic

    /**
     * Event that validates and invokes product adding into cart
     * @returns {*}
     */
    async addToCart() {
        this.updateSelectedValues();
        const { showError } = this.props;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();
        const activeProd = this.getActiveProduct();
        // console.log("addToCart getActiveProduct: ", activeProd);
        // console.log("addToCart getMagentoProduct: ", products);
        await addProductToCart({ products, cartId })
            .then(() => {
                    this.gtmAddToCart(activeProd, products[0].quantity);
                }

            )
            .catch(
                /** @namespace Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                (error) => {
                    if (error) {
                        showError(error);
                    }
                },
            );
    }

    async gtmAddToCart(product, qty) {
        
        let sku = product.sku;
        const cacheLifetime = 86400;

        const options = {
            args: {
                filter: {
                    productSKU:sku,
                },
            },
            isSingleProduct:true,
        };

        const query = [ProductListQuery.getQuery(options)];
        // console.log("Cart adding Product Data: ", product);

        const addingProduct = product;
        var date_time = `${Math.floor(new Date() / 1000)}`;          
        let contentCategory = 'Adding To Cart'
        let contentType = 'product'
        let contentName = addingProduct.name
        let contentIds = [addingProduct.sku]
        let contents = [{id:addingProduct.sku , quantity:qty}]

        agtm.cartPageEvent(
            date_time,
            addingProduct,
            "add",
            "ProductPage",
            "AddToCartClick",
            'en',
            {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
        );


        // executeGet(prepareQuery(query), 'AddingProduct', cacheLifetime)
        //     .then(
        //         /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/executeGet/then */
        //         ({ products: { items } }) => {
        //              let productData = getIndexedProducts(items);
        //              console.log("Cart adding Product productData1: ", productData);
        //             if (productData.length > 0) {
        //                 console.log("Cart adding Product productData2: ", productData[0]);
        //                 const addingProduct = productData[0];
        //                 var date_time = `${Math.floor(new Date() / 1000)}`;          
        //                 let contentCategory = 'Adding To Cart'
        //                 let contentType = 'product'
        //                 let contentName = addingProduct.name
        //                 let contentIds = [addingProduct.sku]
        //                 let contents = [{id:addingProduct.sku , quantity:qty}]

        //                 agtm.cartPageEvent(
        //                 date_time,
        //                 addingProduct,
        //                 "add",
        //                 "ProductPage",
        //                 "AddToCartClick",
        //                 'en',
        //                 {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
        //                 );
        //             }
        //         }
        //     )
        //     .catch(
        //         /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/then/catch/showNotification */
        //         (e) => { 
        //             showNotification(NotificationType.ERROR, __('Error fetching Required Product!'), e);
        //         }
        //     );
    
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    ProductContainer,
);
