import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.override.style';

/** @namespace AlmusbahblendPwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    // TODO implement logic
}

export default ProductPriceComponent;
