import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Page } from 'Component/Header/Header.config';
import { scrollToTop } from 'Util/Browser';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    // TODO implement logic

    requestPage(currentPage = 1, isNext = false) {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            device,
            location: { pathname },
        } = this.props;
        const { isPrefetchValueUsed } = window;

        const isSearch = pathname.includes(Page.SEARCH);
        const isPrefetched = isPrefetchValueUsed && !isWidget && !isSearch;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort: sort ?? undefined,
                filter,
                search,
                pageSize,
                currentPage,
            },
        };

        const infoOptions = {
            args: {
                filter,
                search,
            },
        };
        // if (!isPrefetched) {
        //     requestProductList(options);
        // }
        requestProductList(options);

        if (!isWidget) {
            requestProductListInfo(infoOptions);

            if (!device.isMobile) {
                scrollToTop();
            }
            // waitForPriorityLoad().then(
            // /** @namespace Component/ProductList/Container/ProductListContainer/requestPage/waitForPriorityLoad/then/requestProductListInfo */
            //     () => requestProductListInfo(infoOptions),
            // );

            // if (!device.isMobile) {
            //     scrollToTop();
            // }
        }
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer,
    ),
);
