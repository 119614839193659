import { connect } from 'react-redux';

import {
    CategoryProductListContainer as SourceCategoryProductListContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceComponent/CategoryProductList/CategoryProductList.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/CategoryProductList/Container */
export class CategoryProductListContainer extends SourceCategoryProductListContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryProductListContainer);
