import {
    ProductCardComponent as SourceProductCardComponent,
} from 'SourceComponent/ProductCard/ProductCard.component';

import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';

import { scrollToTop } from '../../util/Browser/Browser';
import './ProductCard.style';

/** @namespace AlmusbahblendPwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCardComponent {
    // TODO implement logic
    handleLinkClick() {
        const { registerSharedElement, isPlp } = this.props;
        // if product values got then call: triggerDLProductClick
        
        if (!isPlp) {
            scrollToTop();
        }

        registerSharedElement(this.imageRef);
    }

    renderMainDetails() {
        const { product: { name } } = this.props;
        
        return (
            <p
              block="ProductCard"
              elem="Name"
              mods={ { isLoaded: !!name } }
            >
                <TextPlaceholder content={ name } length={ TextPlaceHolderLength.LONG } />
            </p>
        );
    }

    renderOfferTag() {
        const {
            product: {
                attributes: { amb_offer_tag: { attribute_value: amb_offer_tag = '' } = {} } = {},
            },
        } = this.props;

        if (!amb_offer_tag) {
            return null;
        }
        
        return (
            <p block="ProductCard" elem="OfferTag">
                <TextPlaceholder content={ amb_offer_tag } length={ TextPlaceHolderLength.LONG } />
            </p>
        );
    }

    renderCardContent() {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderReviews() }
                        { this.renderBrand() }
                        { this.renderMainDetails() }
                        { this.renderOfferTag() }
                        { this.renderPrice() }
                    </div>
                    <div block="ProductCard" elem="VisibleOnHover">
                        { this.renderVisibleOnHover() }
                    </div>
                </div>
            ))
        );
    }
}

export default ProductCardComponent;
