import { connect } from 'react-redux';

import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CategoryPage/CategoryPage.container';

import history from 'Util/History';
import {
    appendWithStoreCode,
    getQueryParam,
    setQueryParams,
} from 'Util/Url';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    // TODO implement logic

    getIsMatchingListFilter() {
        const {
            currentArgs: {
                currentPage,
                sort,
                filter,
            } = {},
        } = this.props;
        const { location } = history;

        /**
         * ? implementation bellow blinks, implementation with categoryIds check only does not show loading when selecting filters.
         * TODO: resolve it to be a combination of these two behaviour
         */

        // Data used to request category matches current data
        // Sanyasirao change -- commented
        // return JSON.stringify(filter) === JSON.stringify(this.getFilter())
        //     && JSON.stringify(sort) === JSON.stringify(this.getSelectedSortFromUrl())
        //     && getQueryParam('page', location) ? (currentPage === +(getQueryParam('page', location) || 1)) : true;

        // Data used to request category matches current data
        return JSON.stringify(filter) === JSON.stringify(this.getFilter())
            && JSON.stringify(sort) === JSON.stringify(this.getSelectedSortFromUrl())
            && currentPage === +(getQueryParam('page', location) || 1);
    }

    getSelectedSortFromUrl() {
        const {
            sortFields: {
                options = [],
            },
            categoryDefaultSortBy,
            isSearchPage,
        } = this.props;
        const { location } = history;

        const {
            sortKey: classDefaultSortKey,
            sortDirection: defaultSortDirection,
        } = this.config;

        /**
         * Default SORT DIRECTION is taken from (sequentially):
         * - URL param "sortDirection"
         * - CategoryPage class property "config"
         */
        const sortDirection = (getQueryParam('sortDirection', location))
            || defaultSortDirection;

        /**
         * Default SORT KEY is taken from (sequentially):
         * - URL param "sortKey"
         * - Category default sort key (Magento 2 configuration)
         * - Product Listing Sort By (Magento 2 configuration)
         * - CategoryPage class property "config"
         * (used when global default sort key does not exist for current category)
         */
        const isClassSortKeyAvailable = !!options.find(
            (sortOption) => sortOption.value === classDefaultSortKey,
        );

        const fallbackSortKey = isClassSortKeyAvailable ? classDefaultSortKey : options[0]?.value;
        const defaultSortKey = isSearchPage ? fallbackSortKey : window.storeConfig?.catalog_default_sort_by || fallbackSortKey;
        const configSortKey = categoryDefaultSortBy || defaultSortKey;

        // const sortKey = getQueryParam('sortKey', location) || configSortKey;
        //  Changing to default sort key
        const sortKey = getQueryParam('sortKey', location) || defaultSortKey;
        
        return {
            sortDirection,
            sortKey,
        };
    }

    onSortChange(sortDirection, sortKey) {
        const { location } = history;
        const { isMobile } = this.props;

        setQueryParams({ sortKey: sortKey.join(','), sortDirection, page: '' }, location, history);
        this.updateMeta();
        if(isMobile) {
            window.location.reload();
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    CategoryPageContainer,
);
