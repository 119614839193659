import {
    Pagination,
    ProductListComponent as SourceProductListComponent,
} from 'SourceComponent/ProductList/ProductList.component';

import './ProductList.override.style';

export {
    Pagination,
};

/** @namespace AlmusbahblendPwa/Component/ProductList/Component */
export class ProductListComponent extends SourceProductListComponent {
    // TODO implement logic

    renderPages() {
        const {
            pages,
            isVisible,
            isLoading,
            isInfiniteLoaderEnabled,
            isWidget,
        } = this.props;

        if (isLoading) {
            return this.renderPage();
        }

        const pageRenders = Object.entries(pages).map(this.renderProductPage.bind(this));

        if (isVisible && isInfiniteLoaderEnabled && !isWidget) { // add placeholders to the end of pages if needed
            const key = Math.max(...(Object.keys(pages))) + 1; // the key should match next page key

            pageRenders.push(this.renderPage({ key }));
        }

        return pageRenders;
    }
}

export default ProductListComponent;
