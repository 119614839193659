import {
    CategoryFilterOverlay,
    CategoryPageComponent as SourceCategoryPageComponent,
    CategorySort,
    FilterIcon,
    GridIcon,
    ListIcon,
} from 'SourceRoute/CategoryPage/CategoryPage.component';

import './CategoryPage.override.style';

export {
    CategoryFilterOverlay,
    FilterIcon,
    GridIcon,
    ListIcon,
    CategorySort,
};

/** @namespace AlmusbahblendPwa/Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPageComponent {
    // TODO implement logic
}

export default CategoryPageComponent;
