import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace AlmusbahblendPwa/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    // TODO implement logic
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
