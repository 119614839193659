import {
    AddToCart as SourceAddToCart,
    FieldContainer,
    GroupedProductList,
    ProductBundleOptions,
    ProductCompareButton,
    ProductComponent,
    ProductConfigurableAttributes,
    ProductCustomizableOptions,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductReviewRating,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';

export {
    ProductReviewRating,
    ProductConfigurableAttributes,
    FieldContainer,
    ProductCustomizableOptions,
    ProductBundleOptions,
    GroupedProductList,
    ProductCompareButton,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductWishlistButton,
    ProductComponent,
};

// TODO: implement AddToCart
export const AddToCart = SourceAddToCart;

export default ProductComponent;
